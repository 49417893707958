import React, { useMemo, Fragment } from "react";
import { Pagination as PaginationContainer, PaginationItem, PaginationLink } from "reactstrap";

import Config from "../../Config";

const MAX_PAGE_VIEW_LIMIT = 3;
const MINIMUM_PAGE_TO_DISPLAY = 6;

const Pagination = (props) => {
    const { currentPage, totalData, rowLimit = Config.LIMIT, onPageClick } = props;
    const totalPages = useMemo(() => {
        return Math.ceil(totalData / rowLimit);
    }, [totalData, rowLimit]);

    const handlePageClick = (val) => {
        if (onPageClick && typeof onPageClick === "function") {
            onPageClick(val);
        }
    };

    const handlePreviousClick = () => {
        if (currentPage > 1) {
            handlePageClick(currentPage - 1);
        }
    };

    const handleNextClick = () => {
        if (currentPage < totalPages) {
            handlePageClick(currentPage + 1);
        }
    };

    const renderPages = () => {
        let pages =
            totalPages > MAX_PAGE_VIEW_LIMIT
                ? new Array(MAX_PAGE_VIEW_LIMIT).fill("")
                : new Array(totalPages).fill("");

        return pages.map((_, index) => {
            return (
                <PaginationItem active={index + 1 === currentPage} key={index}>
                    <PaginationLink tag="button" onClick={() => handlePageClick(index + 1)}>
                        {index + 1}
                    </PaginationLink>
                </PaginationItem>
            );
        });
    };

    const renderMiddle = () => {
        return (
            <>
                <PaginationItem active={1 === currentPage}>
                    <PaginationLink tag="button" onClick={() => handlePageClick(1)}>
                        1
                    </PaginationLink>
                </PaginationItem>
                {currentPage >= totalPages - 2 ? (
                    <>
                        <PaginationItem active={2 === currentPage}>
                            <PaginationLink tag="button" onClick={() => handlePageClick(2)}>
                                2
                            </PaginationLink>
                        </PaginationItem>
                        <PaginationItem active={3 === currentPage}>
                            <PaginationLink tag="button" onClick={() => handlePageClick(3)}>
                                3
                            </PaginationLink>
                        </PaginationItem>

                        <PaginationItem active={false}>
                            <PaginationLink tag="button">....</PaginationLink>
                        </PaginationItem>
                    </>
                ) : (
                    <PaginationItem active={false}>
                        <PaginationLink tag="button">....</PaginationLink>
                    </PaginationItem>
                )}
            </>
        );
    };

    const renderPagesInLimit = () => {
        let pages = totalPages <= MINIMUM_PAGE_TO_DISPLAY ? new Array(totalPages).fill("") : [];

        return pages.map((_, index) => {
            return (
                <PaginationItem active={index + 1 === currentPage} key={index}>
                    <PaginationLink tag="button" onClick={() => handlePageClick(index + 1)}>
                        {index + 1}
                    </PaginationLink>
                </PaginationItem>
            );
        });
    };

    console.log(totalPages > MINIMUM_PAGE_TO_DISPLAY);

    return (
        totalPages > 1 && (
            <PaginationContainer className="pagination pagination-rounded justify-content-end mb-2">
                <PaginationItem disabled={currentPage === 1}>
                    <PaginationLink previous tag="button" onClick={handlePreviousClick}>
                        <i className="bx bx-chevron-left"></i>
                    </PaginationLink>
                </PaginationItem>

                {totalPages <= MINIMUM_PAGE_TO_DISPLAY
                    ? renderPagesInLimit()
                    : currentPage > MAX_PAGE_VIEW_LIMIT
                    ? renderMiddle()
                    : renderPages()}

                {/* {currentPage > MAX_PAGE_VIEW_LIMIT + 1 && (
                    <PaginationItem active={false}>
                        <PaginationLink tag="button">....</PaginationLink>
                    </PaginationItem>
                )} */}

                {!Boolean(totalPages <= MINIMUM_PAGE_TO_DISPLAY) &&
                    totalPages > MAX_PAGE_VIEW_LIMIT && (
                        <Fragment>
                            {currentPage != totalPages - 1 && currentPage > totalPages - 1 && (
                                <PaginationItem>
                                    <PaginationLink
                                        tag="button"
                                        onClick={() => handlePageClick(currentPage - 2)}
                                    >
                                        {currentPage - 2}
                                    </PaginationLink>
                                </PaginationItem>
                            )}

                            {currentPage > MAX_PAGE_VIEW_LIMIT && currentPage != totalPages - 2 && (
                                <PaginationItem>
                                    <PaginationLink
                                        tag="button"
                                        onClick={() => handlePageClick(currentPage - 1)}
                                    >
                                        {currentPage - 1}
                                    </PaginationLink>
                                </PaginationItem>
                            )}
                            {totalPages >= MINIMUM_PAGE_TO_DISPLAY && (
                                <PaginationItem active={currentPage > MAX_PAGE_VIEW_LIMIT}>
                                    <PaginationLink tag="button">
                                        {currentPage > MAX_PAGE_VIEW_LIMIT ? currentPage : "..."}
                                    </PaginationLink>
                                </PaginationItem>
                            )}

                            {currentPage > MAX_PAGE_VIEW_LIMIT &&
                                currentPage !== totalPages &&
                                currentPage !== totalPages - 1 && (
                                    <PaginationItem>
                                        <PaginationLink
                                            tag="button"
                                            onClick={() => handlePageClick(currentPage + 1)}
                                        >
                                            {currentPage + 1}
                                        </PaginationLink>
                                    </PaginationItem>
                                )}

                            {currentPage >= MAX_PAGE_VIEW_LIMIT + 1 &&
                                currentPage != totalPages &&
                                currentPage != totalPages - 1 &&
                                currentPage != totalPages - 2 && (
                                    <PaginationItem active={false}>
                                        <PaginationLink tag="button">....</PaginationLink>
                                    </PaginationItem>
                                )}
                            {totalPages >= MINIMUM_PAGE_TO_DISPLAY &&
                                currentPage !== totalPages - 2 &&
                                currentPage < 4 && (
                                    <PaginationItem>
                                        <PaginationLink
                                            tag="button"
                                            onClick={() => handlePageClick(totalPages - 2)}
                                        >
                                            {totalPages - 2}
                                        </PaginationLink>
                                    </PaginationItem>
                                )}
                            {currentPage !== totalPages - 1 && currentPage < 4 && (
                                <PaginationItem>
                                    <PaginationLink
                                        tag="button"
                                        onClick={() => handlePageClick(totalPages - 1)}
                                    >
                                        {totalPages - 1}
                                    </PaginationLink>
                                </PaginationItem>
                            )}
                            {currentPage !== totalPages && (
                                <PaginationItem>
                                    <PaginationLink
                                        tag="button"
                                        onClick={() => handlePageClick(totalPages)}
                                    >
                                        {totalPages}
                                    </PaginationLink>
                                </PaginationItem>
                            )}
                        </Fragment>
                    )}
                <PaginationItem disabled={currentPage === totalPages}>
                    <PaginationLink next tag="button" onClick={handleNextClick}>
                        <i className="bx bx-chevron-right"></i>
                    </PaginationLink>
                </PaginationItem>
            </PaginationContainer>
        )
    );
};

export default Pagination;
