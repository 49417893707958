import { injectBaseConstantMethods } from "./BaseConstants";

const ANALYTICS_TYPES = {
    USERS: "users",
    MATCHES: "matches",
    REVENUE: "revenue",
    REPORTS: "reports",
    INQUERIES: "inqueries",
    CUSTOMERS: "customers"
};

const displayTextKeys = {
    [ANALYTICS_TYPES.USERS]: "Total Users",
    [ANALYTICS_TYPES.MATCHES]: "Total Matches",
    [ANALYTICS_TYPES.REVENUE]: "Total Revenue",
    [ANALYTICS_TYPES.REPORTS]: "Total Reports",
    [ANALYTICS_TYPES.INQUERIES]: "Total Enquiries",
    [ANALYTICS_TYPES.CUSTOMERS]: "Total Customers"
};

const labelClass = {
    [ANALYTICS_TYPES.USERS]: "info",
    [ANALYTICS_TYPES.MATCHES]: "info",
    [ANALYTICS_TYPES.REVENUE]: "info",
    [ANALYTICS_TYPES.REPORTS]: "info",
    [ANALYTICS_TYPES.INQUERIES]: "info",
    [ANALYTICS_TYPES.CUSTOMERS]: "info"
};

export default injectBaseConstantMethods(ANALYTICS_TYPES, displayTextKeys, labelClass);
