var Config = {
    LIMIT: "10",
    PERSIST_SECRET_KEY: "REDUX_PERSIST_STORE_KEY",
    ENVIRONMENT: "LOCAL",
    ENVIRONMENTS: {
        LOCAL: {
            API_URL: "https://api.halalconnections.com"
        },
        DEVELOPMENT: {
            API_URL: "https://api.halalconnections.com"
        },
        STAGING: {
            API_URL: "https://api.halalconnections.com"
        },
        PRODUCTION: {
            API_URL: "https://api.halalconnections.com"
        }
    }
};

Config.env = () => {
    return Config.ENVIRONMENTS[Config.ENVIRONMENT];
};

export default Config;