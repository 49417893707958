import React, { Component } from "react";
import { Container, Row, Button } from "reactstrap";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";

import "./dashboard.scss";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Widget } from "../../components/Widget";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import { DashboardAnalyticsTypes } from "../../constants";
import { getAnalyticsData } from "../../store/actions";
//import { getFormattedAmountWithCurrency } from "../../utils/commonUtils";

const RenderCardItems = ({ heading, value, isBold = false }) => {
    return (
        <div className="d-flex justify-content-between">
            <p className={`mb-1 ${isBold && "font-weight-bold mb-2"}`}>{heading}</p>
            <p className={`mb-1 ${isBold && "font-weight-bold mb-2"}`}>{value}</p>
        </div>
    );
};

class Dashboard extends Component {
    componentDidMount() {
        this.props.getAnalyticsData(DashboardAnalyticsTypes.CUSTOMERS);
        this.props.getAnalyticsData(DashboardAnalyticsTypes.INQUERIES);
        this.props.getAnalyticsData(DashboardAnalyticsTypes.MATCHES);
        this.props.getAnalyticsData(DashboardAnalyticsTypes.REPORTS);
        this.props.getAnalyticsData(DashboardAnalyticsTypes.REVENUE);
        this.props.getAnalyticsData(DashboardAnalyticsTypes.USERS);
    }

    handleCardPress = (type) => {
        this.props.history.push(getRoute(APP_ROUTES.DASHBOARD_FILTERS, { type }));
    };

    render() {
        const {
            userData,
            matchesData,
            customerData,
            inqueriesData,
            reportsData
            //  revenueData
        } = this.props;
        return (
            <React.Fragment>
                <div className="page-content dashboard-content">
                    <Container fluid>
                        <Breadcrumbs
                            title={this.props.t("Dashboard")}
                            breadcrumbItem={this.props.t("Dashboard")}
                        />
                    </Container>
                    <Row>
                        <Widget
                            md="4"
                            isLoading={userData.fetching}
                            cardData={
                                <div>
                                    <RenderCardItems
                                        isBold={true}
                                        heading="Total Users"
                                        value={userData?.TotalUsers || 0}
                                    />
                                    <RenderCardItems heading="Users" value={userData?.Users || 0} />
                                    <RenderCardItems
                                        heading="Guardian"
                                        value={userData?.Guardians || 0}
                                    />
                                    <RenderCardItems
                                        heading="Total Active Users"
                                        value={userData?.TotalActiveUsers || 0}
                                    />
                                    <div className="d-flex flex-row-reverse">
                                        <Button
                                            onClick={() =>
                                                this.handleCardPress(DashboardAnalyticsTypes.USERS)
                                            }
                                            size="sm"
                                            color="link"
                                            className="link-btn btn btn-link waves-effect text-primary"
                                        >
                                            View Details
                                            <i className="mdi mdi-chevron-right"></i>
                                        </Button>
                                    </div>
                                </div>
                            }
                            description={true}
                        />
                        <Widget
                            md="4"
                            isLoading={customerData.fetching}
                            cardData={
                                <div>
                                    <RenderCardItems
                                        isBold={true}
                                        heading="Total Customers"
                                        value={customerData?.TotalCustomers || 0}
                                    />
                                    <RenderCardItems
                                        heading="Free Users"
                                        value={customerData?.FreeUsers || 0}
                                    />
                                    <RenderCardItems
                                        heading="Paid Users"
                                        value={customerData?.PaidUsers || 0}
                                    />
                                    <div
                                        className="d-flex flex-row-reverse"
                                        style={{ marginTop: "25px" }}
                                    >
                                        <Button
                                            onClick={() =>
                                                this.handleCardPress(
                                                    DashboardAnalyticsTypes.CUSTOMERS
                                                )
                                            }
                                            size="sm"
                                            color="link"
                                            className="link-btn btn btn-link waves-effect text-primary"
                                        >
                                            View Details
                                            <i className="mdi mdi-chevron-right"></i>
                                        </Button>
                                    </div>
                                </div>
                            }
                            description={true}
                        />
                        <Widget
                            md="4"
                            isLoading={matchesData.fetching}
                            cardData={
                                <div>
                                    <RenderCardItems
                                        isBold={true}
                                        heading="Total Matches Shared"
                                        value={matchesData?.TotalMatchesShared || 0}
                                    />
                                    <RenderCardItems
                                        heading="No. of matches made"
                                        value={matchesData?.TotalMatchesMade || 0}
                                    />
                                    <RenderCardItems
                                        heading="No. of matches ended"
                                        value={matchesData?.TotalMatchesEnded || 0}
                                    />
                                    <RenderCardItems
                                        heading="No. of matches expired"
                                        value={matchesData?.TotalMatchesExpired || 0}
                                    />
                                    <div className="d-flex flex-row-reverse">
                                        <Button
                                            onClick={() =>
                                                this.handleCardPress(
                                                    DashboardAnalyticsTypes.MATCHES
                                                )
                                            }
                                            size="sm"
                                            color="link"
                                            className="link-btn btn btn-link waves-effect text-primary"
                                        >
                                            View Details
                                            <i className="mdi mdi-chevron-right"></i>
                                        </Button>
                                    </div>
                                </div>
                            }
                            description={true}
                        />
                        {/* <Widget
                            md="4"
                            isLoading={revenueData.fetching}
                            cardData={
                                <div>
                                    <RenderCardItems
                                        isBold={true}
                                        heading="Revenue Earned"
                                        value={`${getFormattedAmountWithCurrency(
                                            revenueData?.RevenueEarned || 0
                                        )}`}
                                    />
                                    <RenderCardItems
                                        heading="Payment Plans Purchased (Monthly)"
                                        value={revenueData?.TotalMonthlyPaymentPlansPurchased || 0}
                                    />
                                    <RenderCardItems
                                        heading="Payment Plans Purchased (Quarterly)"
                                        value={
                                            revenueData?.TotalQuarterlyPaymentPlansPurchased || 0
                                        }
                                    />
                                    <RenderCardItems
                                        heading="Revenue Plans Purchased (Yearly)"
                                        value={revenueData?.TotalYearlyPaymentPlansPurchased || 0}
                                    />
                                    <div className="d-flex flex-row-reverse">
                                        <Button
                                            onClick={() =>
                                                this.handleCardPress(
                                                    DashboardAnalyticsTypes.REVENUE
                                                )
                                            }
                                            size="sm"
                                            color="link"
                                            className="link-btn btn btn-link waves-effect text-primary"
                                        >
                                            View Details
                                            <i className="mdi mdi-chevron-right"></i>
                                        </Button>
                                    </div>
                                </div>
                            }
                            description={true}
                        /> */}
                    </Row>
                    <Row>
                        <Widget
                            md="4"
                            isLoading={reportsData.fetching}
                            cardData={
                                <div>
                                    <RenderCardItems
                                        isBold={true}
                                        heading="Total Reports"
                                        value={reportsData?.TotalReports || 0}
                                    />
                                    <RenderCardItems
                                        heading="Resolved Reports"
                                        value={reportsData?.ResolvedReports || 0}
                                    />
                                    <RenderCardItems
                                        heading="Active Reports"
                                        value={reportsData?.ActiveReports || 0}
                                    />
                                    <div className="d-flex flex-row-reverse">
                                        <Button
                                            onClick={() =>
                                                this.handleCardPress(
                                                    DashboardAnalyticsTypes.REPORTS
                                                )
                                            }
                                            size="sm"
                                            color="link"
                                            className="link-btn btn btn-link waves-effect text-primary"
                                        >
                                            View Details
                                            <i className="mdi mdi-chevron-right"></i>
                                        </Button>
                                    </div>
                                </div>
                            }
                            description={true}
                        />
                        <Widget
                            md="4"
                            isLoading={inqueriesData.fetching}
                            cardData={
                                <div>
                                    <RenderCardItems
                                        isBold={true}
                                        heading="Total Enquiries"
                                        value={inqueriesData?.TotalInqueries || 0}
                                    />
                                    <RenderCardItems
                                        heading="Resolved"
                                        value={inqueriesData?.ResolvedInqueries || 0}
                                    />
                                    <RenderCardItems
                                        heading="Active"
                                        value={inqueriesData?.ActiveInqueries || 0}
                                    />
                                    <div className="d-flex flex-row-reverse">
                                        <Button
                                            onClick={() =>
                                                this.handleCardPress(
                                                    DashboardAnalyticsTypes.INQUERIES
                                                )
                                            }
                                            size="sm"
                                            color="link"
                                            className="link-btn btn btn-link waves-effect text-primary"
                                        >
                                            View Details
                                            <i className="mdi mdi-chevron-right"></i>
                                        </Button>
                                    </div>
                                </div>
                            }
                            description={true}
                        />
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userData: state.analytics[DashboardAnalyticsTypes.USERS],
        matchesData: state.analytics[DashboardAnalyticsTypes.MATCHES],
        customerData: state.analytics[DashboardAnalyticsTypes.CUSTOMERS],
        inqueriesData: state.analytics[DashboardAnalyticsTypes.INQUERIES],
        reportsData: state.analytics[DashboardAnalyticsTypes.REPORTS],
        revenueData: state.analytics[DashboardAnalyticsTypes.REVENUE]
    };
};

const mapDispatchToProps = {
    getAnalyticsData
};

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
